import request from '@/utils/request.js';
let baseUrl
if (location.href.includes('templateApp') || location.href.includes('singleProductApp')) {
    baseUrl = 'App'
} else {
    baseUrl = ''
}
//获取列表
export function getList(data) {
    return request().get(`/api/log/v1/platform/event/log/list`, { params: data })
}
//获取总数
export function getNum(data) {
    return request().get(`/api/log/v1/platform/event/log/list/info`, { params: data })
}
//获取详情
export function getDetails(data) {
    return request().get(`/api/log/v1/platform/event/log/detail`, { params: data })
}

//获取操作名称
export function fetchActionName(data) {
    return request().get(`/api/log/v1/platform/event/log/actions`, { params: data })
}

