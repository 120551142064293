<template>
  <div class="ace-container">
    <!-- 官方文档中使用 id，这里禁止使用，在后期打包后容易出现问题，使用 ref 或者 DOM 就行 -->
    <div ref="ace" class="ace-editor" :class="className"></div>
  </div>
</template>

<script>
import ace from "ace-builds";
import "ace-builds/webpack-resolver"; // 在 webpack 环境中使用必须要导入
import "ace-builds/src-noconflict/theme-monokai"; // 默认设置的主题
import "ace-builds/src-noconflict/theme-chrome"; // 默认设置的主题
import "ace-builds/src-noconflict/mode-javascript"; // 默认设置的语言模式
import "ace-builds/src-min-noconflict/mode-python"; // python
import "ace-builds/src-min-noconflict/mode-batchfile"; // bash
import "ace-builds/src-min-noconflict/mode-powershell"; // powershell
import "ace-builds/src-min-noconflict/mode-yaml"; // yaml
import "ace-builds/src-min-noconflict/mode-java"; // java
import "ace-builds/src-min-noconflict/mode-json"; // json
import "ace-builds/src-min-noconflict/mode-text"; // 文本
import "ace-builds/src-noconflict/snippets/text";
import "ace-builds/src-min-noconflict/ext-language_tools";

export default {
  name: "AceEditor",
  props: {
    className: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    themePath: {
      type: String,
      default: "ace/theme/monokai",
    },
    modePath: {
      type: String,
      default: "json",
    },
    maxLines: {
      type: Number,
      default: 20,
    },
    minLines: {
      type: Number,
      default: 20,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    //取消语法检查
    check: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      aceEditor: null,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue === "" || newValue) {
          this.aceEditor.setValue(newValue);
          this.aceEditor.moveCursorTo(0, 0);//设置光标位置
        }
      },
    },
    modePath: {
      handler(newValue) {
        if (newValue) {
          this.aceEditor.getSession().setMode("ace/mode/" + newValue);
        }
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.aceEditor = ace.edit(this.$refs.ace, {
        value: this.value,
        maxLines: this.maxLines, // 最大行数，超过会自动出现滚动条
        minLines: this.minLines, // 最小行数，还未到最大行数时，编辑器会自动伸缩大小
        fontSize: 14, // 编辑器内字体大小
        theme: this.themePath, // 默认设置的主题
        mode: "ace/mode/" + this.modePath, // 默认设置的语言模式
        tabSize: 4, // 制表符设置为 4 个空格大小
      });
      // 激活自动提示
      this.aceEditor.setOptions({
        enableSnippets: true,
        enableLiveAutocompletion: true,
        enableBasicAutocompletion: true,
      });
      this.aceEditor.session.setUseWrapMode(true); //设置自动换行
      if (!this.check) {
        this.aceEditor.session.setUseWorker(false); //取消语言模式的语法检查
      }
      if (this.readOnly) {
        this.aceEditor.setReadOnly(true);
      }
      this.aceEditor.getSession().on("change", this.change);
    },
    change() {
      this.$emit("input", this.aceEditor.getSession().getValue());
    },
    destroy() {
      this.aceEditor.destroy();
      this.aceEditor.container.remove();
    },
    resize() {
      this.aceEditor.destroy();
      // this.aceEditor.container.remove();
      this.$nextTick(() => {
        this.init();
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.ace-container {
  position: relative;
  .ace-editor {
    width: 500px;
    height: 200px;
  }
  .bookmarklet {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    z-index: 2;
    cursor: pointer;
    border-width: 9px;
    border-style: solid;
    border-color: lightblue gray gray rgb(206, 173, 230);
    border-image: initial;
  }
}
</style>