<template>
  <div class="screen" :style="{ height: hide ? '58px' : height }">
    <div class="flex between">
      <div style="color: #262626; font-weight: 600; margin-bottom: 20px">
        查询审计时间
      </div>
      <div class="flex between">
        <slot name="topRight"></slot>
        <Sub
          @search="search"
          @reset="reset"
          v-if="subSlot === 5"
          class="flex between"
          style="margin-left: 20px"
          :keepformState="keepformState"
        />
        <a-button type="link" class="handle" @click="handle">
          <span
            >{{ hide ? "展开" : "收起" }}
            <DownOutlined class="icon" :class="hide ? '' : 'rotate'"
          /></span>
        </a-button>
      </div>
    </div>
    <div style="margin-top: 4px" class="bottom_height_box">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Sub from "@/components/list/Sub.vue";
import { DownOutlined } from "@ant-design/icons-vue";
export default {
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    subSlot: {
      type: Number,
      default: 10,
    },
    formState: {
      type: Object,
      default: {},
    },
    keepformState: {
      type: Object,
      default: {},
    },
  },
  components: {
    Sub,
    DownOutlined,
  },
  data() {
    return {
      hide: true,
      height: "180px",
    };
  },
  methods: {
    handle() {
      let el = document.querySelector(".bottom_height_box");
      this.height = el.clientHeight + 58 + 10 + "px";
      this.hide = !this.hide;
    },
    search() {
      this.$store.commit("searchFlagAdd");
      this.$emit("search");
    },
    reset() {
      this.$emit(
        "update:formState",
        JSON.parse(JSON.stringify(this.keepformState))
      );
      this.$emit("reset");
      this.search();
    },
  },
};
</script>
<style lang="scss" scoped>
.screen {
  font-size: 12px;
  color: #8c8c8c;
  line-height: 20px;
  font-weight: 400;
  border-radius: 4px;
  background: #fff;
  padding: 10px 40px 10px 40px;
  font-size: 12px;
  overflow: hidden;
  transition: all 0.3s;
}
.handle {
  padding-top: 12px;
}
.icon {
  transition: all 0.3s;
}
.icon.rotate {
  transform: rotate(-180deg);
}
</style>
<style lang="scss">
.screen .ant-select-selector {
  height: 30px !important;
}
.screen .input .ant-input {
  height: 20px !important;
}
.screen .ant-calendar-range-picker-input {
  height: 20px !important;
}
</style>