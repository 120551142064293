
import { defineComponent } from "vue";
import { getList, getNum, fetchActionName } from "@/api/log/api.js";
import Screen from "@/components/list/Screen2.vue";
import TableTab from "@/components/list/TableTab.vue";
import Pagination from "@/components/list/Pagination.vue";
import moment from "moment";
import Modal from "@/components/log/Modal.vue";

import debounce from 'lodash/debounce';
export default defineComponent({
  components: {
    Screen,
    TableTab,
    Pagination,
    Modal,
  },
  data() {
    this.handleActionNameFetch = debounce(this.handleActionNameFetch, 800);
    return {
      formState: {
        activeTime: [
          moment(new Date()).startOf("day").format("x"),
          moment(new Date()).endOf("day").format("x"),
        ],
      },
      keepformState: {
        activeTime: [
          moment(new Date()).startOf("day").format("x"),
          moment(new Date()).endOf("day").format("x"),
        ],
      },
      // 操作简称
      fetching: false,
      lastFetchId: 0,
      actionNames: [],
      //table的数据
      data: [],
      //table的列名字
      columns: [
        {
          title: "请求编号",
          dataIndex: "traceId",
          slots: { customRender: "traceId" },
          // width: 140,
          align: "center",
          ellipsis: true,
        },
        {
          title: "操作人",
          children: [
            {
              title: "用户名",
              dataIndex: "nickname",
              width: 100,
            },
            {
              title: "角色",
              dataIndex: "roleName",
              slots: { customRender: "roleName" },
              width: 90,
            },
            {
              title: "终端",
              dataIndex: "remoteType",
              slots: { customRender: "remoteType" },
              ellipsis: true,
              width: 70,
            },
            {
              title: "终端IP",
              dataIndex: "remoteIp",
              slots: { customRender: "remoteIp" },
              width: 110,
            },
          ],
        },
        {
          title: "操作对象",
          children: [
            {
              title: "范围",
              dataIndex: "domain",
              slots: { customRender: "domain" },
              width: 60,
            },
            {
              title: "租户名称",
              dataIndex: "tenantName",
              slots: { customRender: "tenantName" },
              width: 80,
            },
            {
              title: "应用",
              dataIndex: "appName",
              slots: { customRender: "appName" },
              width: 100,
            },
            {
              title: "操作",
              dataIndex: "actionName",
              slots: { customRender: "actionName" },
              ellipsis: true,
              // width: 170,
            },
            {
              title: "操作简称",
              dataIndex: "actionBrief",
              slots: { customRender: "actionBrief" },
              ellipsis: true,
              // width: 120,
            },
          ],
        },
        {
          title: "操作方式",
          children: [
            {
              title: "方法",
              dataIndex: "method",
              slots: { customRender: "method" },
              width: 65,
            },
            {
              title: "状态",
              dataIndex: "status",
              slots: { customRender: "status" },
              width: 60,
            },
            {
              title: "耗时",
              dataIndex: "reqTime",
              slots: { customRender: "reqTime" },
              width: 60,
            },
            {
              title: "结果",
              dataIndex: "result2",
              slots: { customRender: "result2" },
              width: 80,
            },
          ],
        },
        {
          title: "创建时间",
          dataIndex: "logTime",
          slots: { customRender: "logTime" },
          width: 140,
        },
      ],
      //总数据量
      total: 0,
      searchFlag: false,
      //table的布局
      scroll: { x: "100%" },
      loading: true,
      rules: [
        {
          trigger: "blur",
          required: true,
          validator: async (rule, value, item) => {},
        },
      ],
      rulesAppName: [
        {
          trigger: "blur",
          validator: async (rule, value, item) => {
            if (
              ((this as any).formState.actionName) &&
              !value
            ) {
              return Promise.reject("填写操作名时，需要输入应用名");
            }
          },
        },
      ],
      rowClick: (record) => ({
        onClick: (e) => {
          //第一列不触发
          if (!e.target.innerHTML.includes('class="traceId"')) {
            this.clickRow(record);
          }
        },
      }),
    };
  },
  methods: {
    handleActionNameFetch(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.fetching = true;
      fetchActionName({ act: value}).then((res: any) => {
        if (fetchId === this.lastFetchId) {
          // console.log(res.data.data);
          this.actionNames = res.data.data;
        }
      }).finally(() => {
        if (fetchId === this.lastFetchId) {
          this.fetching = false;
        }
      });
    },
    handleActionNameChange(value) {
      let action = this.actionNames.find((item: any) => item.id === value);
      if (action) {
        (this as any).formState.appName = (action as any).appName;
        (this as any).formState.actionName = (action as any).value;
      }
    },
    reset() {
      (this as any).$refs.formRef.clearValidate();
    },
    //点击行触发
    clickRow(record) {
      // console.log(record);
      (this as any).$refs.Modal.show(record, this.formState.activeTime);
    },
    changePagination(page) {
      this.getData(page.pageSize, page.pageNum);
    },
    //获取列表
    getData(pageSize = 20, pageNum = 1) {
      let params: any = {
        startAt: this.formState.activeTime && this.formState.activeTime[0],
        endAt: this.formState.activeTime && this.formState.activeTime[1],
        traceId: (this as any).formState.traceId,
        nickname: (this as any).formState.nickname,
        roleName: (this as any).formState.roleName,
        remoteIp: (this as any).formState.remoteIp,
        clientId: (this as any).formState.clientId,
        appName: (this as any).formState.appName,
        tenantInfo: (this as any).formState.tenantInfo,
        actionName: (this as any).formState.actionName,
        actionBrief: (this as any).formState.actionBrief,
      };
      getNum(params).then((res: any) => {
        this.total = res.data.data.totalNumber;
      });
      this.loading = true;
      let data = JSON.parse(JSON.stringify(params));
      data.size = pageSize;
      data.page = pageNum - 1;
      getList(data)
        .then((res: any) => {
          let result = res.data;
          this.data = result.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //搜索
    search() {
      (this as any).$refs.formRef.validate().then(() => {
        this.getData(
          Number(
            sessionStorage.getItem(this.$route.path.replace(/\//g, "") + "Size")
          ) || 20,
          1
        );
      });
    },
  },
  mounted() {
    //获取列表信息
    this.search();
  },
});
