<template>
  <a-modal
    class="modal_log"
    v-model:visible="visible"
    width="1200px"
    centered
    :footer="false"
    @cancel="cancel"
  >
    <template #title>
      <DecorateModalTitle modalTitle="">
        <template v-slot:title>
          <span style="font-size: 16px">日志详情</span>
        </template>
        <span style="color: #595959; font-weight: 400">
          <span
            @click="$store.state.same.copy(data.traceId)"
            style="margin: 0 10px"
            >{{ data.traceId }}</span
          >
          <span>{{ data.logTime }}</span>
        </span>
      </DecorateModalTitle>
    </template>
    <div class="flex between" :class="data.domain === '租户'? 'top_father': ''">
      <div class="top_box">
        <!-- <div class="title_3">操作人</div> -->
        <div>
          <span class="label">用户名</span>
          <span
            class="content"
            :title="data.nickname"
            @click="$store.state.same.copy(data.nickname)"
            >{{ data.nickname }}</span
          >
        </div>
        <div>
          <span class="label"></span>
          <span
            class="content"
            :title="data.accountCode"
            @click="$store.state.same.copy(data.accountCode)"
            >{{ data.accountCode }}</span
          >
        </div>
        <div>
          <span class="label">角色</span>
          <span
            class="content"
            :title="data.roleName"
            @click="$store.state.same.copy(data.roleName)"
            >{{ data.roleName }}</span
          >
        </div>
        <div>
          <span class="label"></span>
          <span
            class="content"
            :title="data.roleCode"
            @click="$store.state.same.copy(data.roleCode)"
            >{{ data.roleCode }}</span
          >
        </div>
        <div>
          <span class="label">终端</span>
          <span
            class="content"
            :title="data.remoteType"
            @click="$store.state.same.copy(data.remoteType)"
            >{{ data.remoteType }}</span
          >
        </div>
        <div>
          <span class="label">终端IP</span>
          <span
            class="content"
            :title="data.remoteIp"
            @click="$store.state.same.copy(data.remoteIp)"
            >{{ data.remoteIp }}</span
          >
        </div>
        <div>
          <span class="label">终端ID</span>
          <span
            class="content"
            :title="data.clientId"
            @click="$store.state.same.copy(data.clientId)"
            >{{ data.clientId }}</span
          >
        </div>

      </div>
      <div class="top_box" v-if="data.domain == '租户'">
        <!-- <div class="title_3">租户信息</div> -->
        <div>
          <span class="label">租户名称</span>
          <span
            class="content"
            :title="data.tenantName"
            @click="$store.state.same.copy(data.tenantName)"
            >{{ data.tenantName }}</span
          >
        </div>
        <div>
          <span class="label"></span>
          <span
            class="content"
            :title="data.tenantCode"
            @click="$store.state.same.copy(data.tenantCode)"
            >{{ data.tenantCode }}</span
          >
        </div>

        <div>
          <span class="label">租户用户</span>
          <span
            class="content"
            :title="data.userTenName"
            @click="$store.state.same.copy(data.userTenName)"
            >{{ data.userTenName }}</span
          >
        </div>
        <div>
          <span class="label"></span>
          <span
            class="content"
            :title="data.userTenCode"
            @click="$store.state.same.copy(data.userTenCode)"
            >{{ data.userTenCode }}</span
          >
        </div>
        <div>
          <span class="label">应用名称</span>
          <span
            class="content"
            :title="data.appTenName"
            @click="$store.state.same.copy(data.appTenName)"
            >{{ data.appTenName }}</span
          >
        </div>

        <div>
          <span class="label">应用编码</span>
          <span
            class="content"
            :title="data.appTenCode"
            @click="$store.state.same.copy(data.appTenCode)"
            >{{ data.appTenCode }}</span
          >
        </div>
      </div>
      <div class="top_box">
        <div class="title_3">操作对象</div>
        <div>
          <span class="label">范围</span>
          <span
            class="content"
            :title="data.domain"
            @click="$store.state.same.copy(data.domain)"
            >{{ data.domain }}</span
          >
        </div>

        <div>
          <span class="label">应用名称</span>
          <span
            class="content"
            :title="data.appName"
            @click="$store.state.same.copy(data.appName)"
            >{{ data.appName }}</span
          >
        </div>
        <div>
          <span class="label">操作名称</span>
          <span
            class="content"
            :title="data.actionName"
            @click="$store.state.same.copy(data.actionName)"
            >{{ data.actionName }}</span
          >
        </div>
        <div>
          <span class="label">操作简称</span>
          <span
            class="content"
            :title="data.actionBrief"
            @click="$store.state.same.copy(data.actionBrief)"
            >{{ data.actionBrief }}</span
          >
        </div>
        <div>
          <span class="label">操作详情</span>
          <span
            class="content"
            :title="data.actionDetail"
            @click="$store.state.same.copy(data.actionDetail)"
            >{{ data.actionDetail }}</span
          >
        </div>
      </div>  

      <div class="top_box">
        <div class="title_3">操作方式</div>
        <div>
          <span class="label">请求方法</span>
          <span
            class="content"
            :title="data.method"
            @click="$store.state.same.copy(data.method)"
            >{{ data.method }}</span
          >
        </div>
        <div>
          <span class="label">请求状态</span>
          <span
            class="status content"
            :class="
              data.status &&
              data.status[0] != 2 &&
              data.status &&
              data.status[0] != 3
                ? 'waring'
                : ''
            "
          >
            {{ data.status }}
          </span>
        </div>
        <div>
          <span class="label">请求时间</span>
          <span
            class="content"
            :title="data.logTime"
            @click="$store.state.same.copy(data.logTime)"
            >{{ data.logTime }}</span
          >
        </div>
        <div>
          <span class="label">操作耗时</span>
          <span
            :class="Number(data.reqTime) >= 0.5 ? 'red' : ''"
            class="content"
            :title="data.reqTime"
            @click="$store.state.same.copy(data.reqTime)"
            >{{ data.reqTime }} s</span
          >
        </div>
        <div>
          <span class="label">操作结果</span>
          <span
            class="content"
            :title="data.result2"
            @click="$store.state.same.copy(data.result2)"
            >{{ data.result2 }}</span
          >
        </div>
      </div>
    </div>
    <div style="margin-top: 10px">
      <AceEditor
        ref="ace"
        :value="host"
        class="ace-editor"
        className="long1"
        :min-lines="2"
        :max-lines="2"
        :mode-path="editScriptType"
        :readOnly="true"
      ></AceEditor>
      <AceEditor
        ref="ace"
        :value="path"
        class="ace-editor"
        className="long2"
        :min-lines="2"
        :max-lines="2"
        :mode-path="editScriptType"
        :readOnly="true"
      ></AceEditor>
    </div>
    <div class="flex" style="padding: 10px 0">
      <div>
        <div class="title_2">
          请求消息
          <a-button @click="showHead" class="btn" type="link"
            >[<span>{{ bodyHeight === 14 ? "隐藏" : "显示" }}</span
            >头信息]</a-button
          >
        </div>
        <AceEditor
          :className="headHeight !== 1 ? 'aceBody1' : ''"
          ref="aceBody1"
          :value="reqBody"
          class="ace-editor"
          :min-lines="bodyHeight"
          :max-lines="bodyHeight"
          :mode-path="editScriptType"
          :readOnly="true"
        ></AceEditor>
        <!--  -->
        <div>
          <AceEditor
            className="aceHead1"
            :class="headHeight === 1 ? 'head' : ''"
            ref="aceHead1"
            :value="headHeight === 1 ? '' : reqHeaders"
            class="ace-editor"
            :min-lines="headHeight"
            :max-lines="headHeight"
            mode-path="yaml"
            :check="false"
            :readOnly="true"
          ></AceEditor>
        </div>
      </div>
      <div>
        <div class="title_2">响应消息</div>
        <div>
          <!-- :className="headHeight!==1?'aceBody2':''" -->
          <AceEditor
            :className="headHeight !== 1 ? 'aceBody2' : ''"
            ref="aceBody2"
            :value="respBody"
            class="ace-editor"
            :min-lines="bodyHeight"
            :max-lines="bodyHeight"
            :mode-path="editScriptType"
            :readOnly="true"
          ></AceEditor>
        </div>
        <!--  -->
        <div :class="headHeight === 1 ? 'head' : ''">
          <AceEditor
            className="aceHead2"
            ref="aceHead2"
            :value="headHeight === 1 ? '' : respHeaders"
            class="ace-editor"
            :min-lines="headHeight"
            :max-lines="headHeight"
            mode-path="yaml"
            :check="false"
            :readOnly="true"
          ></AceEditor>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import AceEditor from "@/components/modification/AceEditor.vue";
import { getDetails } from "@/api/log/api.js";

export default {
  components: {
    DecorateModalTitle,
    AceEditor,
  },
  data() {
    return {
      data: {},
      visible: false,
      editScriptType: "yaml",
      bodyHeight: 26, //或者14
      headHeight: 1, //或者12
    };
  },
  methods: {
    //弹窗关闭复原原样
    cancel() {
      // this.bodyHeight = 26;
      // this.$refs.aceBody1.resize();
      // this.$refs.aceBody2.resize();
      // this.$refs.aceHead1.resize();
      // this.$refs.aceHead2.resize();
    },
    async show(record, activeTime) {
      let res = await getDetails({
        traceId: record.traceId,
        startAt: activeTime[0],
        endAt: activeTime[1],
      });
      if (res.data.success) {
        this.data = res.data.data;
        this.visible = true;
      }
    },
    showHead() {
      this.bodyHeight = this.bodyHeight === 14 ? 26 : 14;
      this.$refs.aceBody1.resize();
      this.$refs.aceBody2.resize();
      this.headHeight = this.headHeight === 1 ? 12 : 1;
      this.$refs.aceHead1.resize();
      this.$refs.aceHead2.resize();
    },
  },
  computed: {
    host() {
      return `# 请求域名
${this.data.host}`;
    },
    path() {
      return `# 请求路径
${this.data.path}`;
    },
    respBody() {
      if (this.data.respBody) {
        try{
          return `${JSON.stringify(JSON.parse(this.data.respBody), null, "\t")}`;
        }catch{
          return this.data.respBody
        }
      } else {
        return "# 响应体为空";
      }
    },
    reqBody() {
      if (this.data.reqBody) {
        try{
          return `${JSON.stringify(JSON.parse(this.data.reqBody), null, "\t")}`;
        }catch{
          return this.data.reqBody
        }
      } else {
        return "# 请求体为空";
      }
    },
    reqHeaders() {
      if (this.data.reqHeaders) {
        return `# 请求头信息
${this.data.reqHeaders}`;
      } else {
        return "# 请求头为空";
      }
    },
    respHeaders() {
      if (this.data.respHeaders) {
        return `# 响应头信息
${this.data.respHeaders}`;
      } else {
        return "# 响应头为空";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  text-align: left;
  padding: 10px;
}
.ace-container :deep(.ace_editor) {
  width: 560px;
  border-radius: 5px;
}
:deep(.ace_editor) {
  transition-property: visibility, height;
  transition-duration: 0.5s;
}

:deep(.ace_editor).long1,
:deep(.ace_editor).long2 {
  width: 1132px !important;
}
:deep(.ace_editor).long1 {
  border-radius: 5px 5px 0 0;
}
:deep(.ace_editor).long2 {
  border-radius: 0 0 5px 5px;
}
:deep(.ace_editor).aceHead1,
:deep(.ace_editor).aceHead2 {
  border-radius: 0 0 5px 5px;
}
:deep(.ace_editor).aceBody2,
:deep(.ace_editor).aceBody1 {
  border-radius: 5px 5px 0 0;
}

.top_box:nth-last-of-type(1){
  margin: 0;
}
.top_box {
  flex: 1;
  margin-right: 10px;
  min-height: 205px;
  border: 1px solid #ccc;
  // width: 33%;
  border-radius: 5px;
  padding: 18px 14px;
  > div {
    height: 24px;
    line-height: 24px;
  }
  > div {
    display: flex;
  }
  .title_3 {
    // font-size: 14px;
    color: #333;
  }
  .label {
    color: #999;
    display: block;
    width: 100px;
  }
  .content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 240px;
  }
}
.top_father {
  .content {
    max-width: 140px;
  }
}
.title_2 {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}
.status {
  // font-weight: 900;
  color: #fff;
  background: #0fbe8f;
  border-radius: 4px;
  padding: 2px 8px;
  display: block;
  text-align: center;
  transform: scale(0.85);
  line-height: 1.7;
}
.status.waring {
  background: #e4373a;
}
.flex {
  justify-content: space-between;
  align-items: flex-start;
}
.head {
  visibility: hidden;
}
// .red {
//   color: #e4373a;
// }
</style>
<style lang="scss">
.modal_log .ant-modal-content {
  height: 870px !important;
}
</style>